






















































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { PossibleAction } from '@/auth/PossibleAction';
import { hasPermission } from '@/auth/AuthService';
import Experiment from '@/models/experiment/Experiment';
import Submission from '@/models/experiment/Submission';
import SubmissionService from '@/services/SubmissionService';
import SuperTask from '@/models/experiment/SuperTask';
import UserService from '@/services/UserService';
import UserInfo from '@/models/users/UserInfo';
import { TaskTypeEnum } from '@/models/experiment/TaskTypeEnum';
import TaskService from '@/services/TaskService';
import ExperimentService from '@/services/ExperimentService';

@Component
export default class EditSubmission extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  protected submission = new Submission('', false, -1);
  protected task!: SuperTask;
  protected experiment!: Experiment;
  protected taskId = -1;
  protected submissionId = -1;
  protected experimentId = -1;
  protected allUsers: UserInfo[] = [];
  protected taskType!: TaskTypeEnum;

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.taskId = parseInt(this.$route.params.taskId);
      this.experimentId = parseInt(this.$route.params.experimentId);
      this.submissionId = parseInt(this.$route.params.submissionId);
      if (Number.isNaN(this.experimentId) || Number.isNaN(this.taskId)) {
        this.$router.replace({ path: '/experiment/admin' });
        return;
      }
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Experiments', to: '/experiment/admin' },
        { text: 'Experiment', to: '/experiment/admin/edit/' + this.experimentId },
        { text: 'Task', to: '/task/admin/edit/' + this.experimentId + '/' + this.taskId },
        { text: 'Edit Submission', active: true },
      ];
      this.setLoading(true);
      this.loadTask();
      this.loadSubmission();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected cancel(): void {
    this.$router.push({ path: '/task/admin/edit/' + this.experimentId + '/' + this.taskId });
  }

  protected loadTask(): void {
    this.setLoading(true);
    TaskService.getTaskType(this.taskId)
      .then((result) => {
        this.setLoading(false);
        this.taskType = TaskTypeEnum[result];
        this.loadTaskFromServer();
        this.loadSubmission();
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load task type ' + backendError.response.data.message, 'danger');
        }
      });
  }

  private loadTaskFromServer() {
    this.setLoading(true);
    let promise;
    if (this.taskType === TaskTypeEnum.NORMAL) {
      promise = TaskService.getNormalTaskById(this.taskId);
    } else if (this.taskType === TaskTypeEnum.REVIEW) {
      promise = TaskService.getReviewTaskById(this.taskId);
    } else {
      promise = TaskService.getGoogleFormsTaskById(this.taskId);
    }
    promise
      .then((result) => {
        this.setLoading(false);
        this.task = result as SuperTask;
        this.getExperiment();
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load task ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected getExperiment(): void {
    this.setLoading(true);
    ExperimentService.getExperimentById(this.experimentId)
      .then((result) => {
        this.setLoading(false);
        this.experiment = result;
        this.getAllUsers();
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load experiment ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected getAllUsers(): void {
    this.setLoading(true);
    UserService.getUserInfoByIdsArray(this.experiment.participants)
      .then((result) => {
        this.setLoading(false);
        this.allUsers = result;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load users ' + backendError.response.data.message, 'danger');
        }
      });
  }

  private loadSubmission(): void {
    this.setLoading(true);
    SubmissionService.getSubmissionById(this.submissionId)
      .then((result) => {
        this.setLoading(false);
        this.submission = result;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load submission ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected saveSubmission(): void {
    this.setLoading(true);
    this.submission.taskId = this.taskId;
    SubmissionService.saveSubmission(this.submission)
      .then(() => {
        this.setLoading(false);
        this.$router.replace({ path: '/task/admin/edit/' + this.experimentId + '/' + this.taskId });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to save', 'Could not add submission. ' + backendError.response.data.message, 'danger');
        }
      });
  }
}
